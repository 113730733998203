<template>
    <div class="certificateView">
        <img src="~@/assets/img/TechnicalCollege/41.png" alt="">
        <div class="school">
            <div class="school_detail">
                <div class="school_detail_left">
                    <img src="~@/assets/img/TechnicalCollege/33.png" alt="">
                </div>
                <div class="school_detail_right">
                    <div>简介:</div>
                    <div style="margin-top: 10px;">国家开放大学（The Open University Of China），简称“国开大”“OUC”，是教育部直属单位
                        [52]，是教育部批准的具有成人教育办学资质的成人学校。
                    </div>
                    <div>学校前身是1978年邓小平同志倡导并批示创办中央广播电视大学，2012年6月，中央广播电视大学更名为国家开放大学。</div>
                </div>
            </div>
        </div>
        <div class="advantage">
            <div class="advantage_detail">
                <div class="detail">
                    <img style="width: 122px;height: 54px;" src="~@/assets/img/TechnicalCollege/34.png" alt="">
                    <div class="d_til">
                        <div>开放教育 <span>四大优势</span> </div>
                        <div>开放式教育形式，小成本提升自己</div>
                    </div>
                    <img style="width: 122px;height: 54px;" src="~@/assets/img/TechnicalCollege/35.png" alt="">
                </div>
                <div class="detail_main">
                    <div class="detail_main1">
                        <div class="d_main_left">
                            <img style="width: 87px;" src="~@/assets/img/TechnicalCollege/37.png" alt="">
                        </div>
                        <div class="d_main_right">
                            <div class="d_main_til">
                                <div>国家承认学历</div>
                                <div class="bg"></div>
                            </div>
                            <div class="d_main_con">
                                国家开放大学办学正规，不仅国家承认学历，在中国高等教育学生信息网上也能查到自己的学籍和学历。
                            </div>
                        </div>
                    </div>
                    <div class="detail_main2">
                        <div class="d_main_left">
                            <img style="width: 87px;" src="~@/assets/img/TechnicalCollege/36.png" alt="">
                        </div>
                        <div class="d_main_right">
                            <div style="width: 227px;" class="d_main_til">
                                <div>学制短，毕业快</div>
                                <div style="width: 227px;" class="bg"></div>
                            </div>
                            <div class="d_main_con">
                                国家开放教育的专升本学制为2年，可申请免试入学。 </div>
                        </div>
                    </div>
                    <div class="detail_main3">
                        <div class="d_main_left">
                            <img style="width: 87px;" src="~@/assets/img/TechnicalCollege/38.png" alt="">
                        </div>
                        <div class="d_main_right">
                            <div class="d_main_til">
                                <div>学历含金量高</div>
                                <div class="bg"></div>
                            </div>
                            <div class="d_main_con">
                                学历可入社保、评职称、考资格证、提干、加薪、考研、考公务员、办留学、办居住等。 </div>
                        </div>
                    </div>
                    <div class="detail_main4">
                        <div class="d_main_left">
                            <img style="width: 87px;" src="~@/assets/img/TechnicalCollege/39.png" alt="">
                        </div>
                        <div class="d_main_right">
                            <div style="width: 227px;" class="d_main_til">
                                <div>学习方式比较灵活</div>
                                <div style="width: 227px;" class="bg"></div>
                            </div>
                            <div class="d_main_con">
                                学生可进行网络进行学习，不用到教室上课，很大程度上减轻了在职人员的复习负担。 </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="detail_right_main">
            <div class="detail_right">
                <div class="detail_right_til">
                    <img src="~@/assets/img/TechnicalCollege/12.png" alt="">
                    常见问题
                </div>
                <div class="question_list">
                    <div v-for="(item, index) in qusetionList" :key="index" class="question_item">
                        <div class="q_ask">
                            <div>Q</div>
                            <div>{{ item.questionName }}</div>
                        </div>
                        <div class="q_answer">
                            <div>A</div>
                            <div class="twoEllipsis"> {{ item.questionAnswer }} </div>
                        </div>
                    </div>
                </div>
                <el-pagination background style="display: flex;justify-content: center;" :page-count="4"
                    class="pagination partner-pagination" :current-page="search.pageNum" :page-size="search.pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total"
                    @current-change="handleCurrentChange" @size-change="handleSizeChange"
                    :page-sizes="[10, 20, 50, 100]" />

            </div>
            <!-- <div style="margin-top: 40px;">
                <submit></submit>
            </div> -->
        </div>

    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
import submit from "@/views/gxGather/school/pages/submit.vue";

export default {
    components: { submit },

    data() {
        return {
            projectCategoryId: '',
            search: {
                pageSize: 10,
                pageNum: 1,
                projectTypeId: '',
                projectCategoryId: '',
            },
            qusetionList: [],
            total: 0
        };
    },
    created() {
        this.search.projectTypeId = localStorage.getItem('projectTypeId');
        this.search.projectCategoryId = localStorage.getItem('projectCategoryId');
        this.getSchoolProblemPcList()
    },
    mounted() { },
    methods: {
        getSchoolProblemPcList() {
            know.getSchoolProblemPcList({ ...this.search }).then((res) => {
                this.qusetionList = res.rows
                this.total = res.total
            });
        },
        handleSizeChange(val) {
            this.search.pageNum = 1
            this.search.pageSize = val
            this.getSchoolProblemPcList()
        },
        handleCurrentChange(val) {
            this.search.pageNum = val
            this.getSchoolProblemPcList()
        },
    },
};
</script>

<style lang="less" scoped>
.certificateView {
    margin-bottom: 20px;
}

.school {
    background: #F0F4F8;
    padding: 36px 0;

    .school_detail {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;

        .school_detail_left {
            position: absolute;
            left: 0;
            width: 284px;
        }

        .school_detail_right {
            width: 993px;
            height: 154px;
            background: #FFFFFF;
            border-radius: 12px 12px 12px 12px;
            padding: 10px 10px 10px 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            >div:nth-child(1) {
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
                font-weight: normal;
                font-size: 20px;
                color: #BC3535;
                font-weight: bold;
            }

            >div:nth-child(2),
            >div:nth-child(3) {
                width: 860px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: normal;
                font-size: 16px;
                color: #444444;
            }
        }
    }
}

.advantage {
    width: 100%;
    height: 800px;
    background: url('~@/assets/img/TechnicalCollege/32.png');
    background-size: 100% 100%;

    .advantage_detail {
        width: 1200px;
        margin: 0 auto;
        padding: 40px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .detail {
            display: flex;
            align-items: center;

            .d_til {
                margin: 0 20px;

                >div:nth-child(1) {
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
                    font-weight: normal;
                    font-size: 40px;
                    font-weight: bold;
                    color: #444444;

                    span {
                        color: #BC3535;

                    }
                }

                >div:nth-child(2) {
                    text-align: center;
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: normal;
                    font-size: 20px;
                    color: #666666;
                }
            }
        }

        .detail_main {
            width: 934px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 50px;

            >div {
                margin-bottom: 20px;
                position: relative;
                display: flex;
                align-items: center;
                padding: 20px;
            }

            .detail_main1 {
                width: 452px;
                height: 259px;
                background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #F7FAFF 100%);
                box-shadow: 0px 2px 6px 1px rgba(8, 54, 143, 0.07);
                border-radius: 100px 6px 8px 6px;
                border: 2px solid #FFFFFF;
            }

            .detail_main2 {
                width: 452px;
                height: 259px;
                background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #F7FAFF 100%);
                box-shadow: 0px 2px 6px 1px rgba(8, 54, 143, 0.07);
                border-radius: 6px 100px 6px 6px;
                border: 2px solid #FFFFFF;
            }

            .detail_main3 {
                width: 452px;
                height: 259px;
                background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #F7FAFF 100%);
                box-shadow: 0px 2px 6px 1px rgba(8, 54, 143, 0.07);
                border-radius: 6px 6px 6px 100px;
                border: 2px solid #FFFFFF;
            }

            .detail_main4 {
                width: 452px;
                height: 259px;
                background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #F7FAFF 100%);
                box-shadow: 0px 2px 6px 1px rgba(8, 54, 143, 0.07);
                border-radius: 6px 6px 100px 6px;
                border: 2px solid #FFFFFF;
            }

            .d_main_right {
                padding-left: 40px;

                .d_main_til {
                    width: 182px;
                    position: relative;

                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
                    font-weight: normal;
                    font-size: 26px;
                    color: #444444;
                    font-weight: bold;
                    height: 40px;

                    >div:nth-child(1) {
                        z-index: 99;
                    }
                }

                .bg {
                    position: absolute;
                    bottom: 0;
                    width: 182px;
                    height: 18px;
                    background: linear-gradient(270deg, #FDCDA5 0%, #FC7777 100%);
                    border-radius: 0px 0px 0px 0px;
                }

                .d_main_con {
                    height: 120px;
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: normal;
                    font-size: 18px;
                    color: #555555;
                    margin-top: 10px;
                }
            }
        }
    }

}

.detail_right_main {
    background: #FFFFFF;
    padding: 20px;

}

.detail_right {
    width: 1200px;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;

    .detail_right_til {
        height: 70px;
        text-align: center;
        line-height: 70px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
        font-weight: normal;
        font-size: 26px;
        color: #444444;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
    }

    .question_list {
        padding: 30px;
        padding-top: 0;

        .question_item {
            margin-top: 25px;

            .q_ask {
                display: flex;
                align-items: baseline;

                >div:nth-child(1) {
                    width: 20px;
                    height: 20px;
                    background: #FECD61;
                    border-radius: 3px 3px 3px 3px;
                    text-align: center;
                    line-height: 20px;
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: normal;
                    font-size: 15px;
                    color: #333333;
                    flex-shrink: 0;

                }

                >div:nth-child(2) {
                    padding: 5px 20px;
                    background: #FECD61;
                    border-radius: 15px 15px 15px 15px;
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: normal;
                    font-size: 15px;
                    color: #333333;
                    margin-left: 10px;
                }
            }

            .q_answer {
                display: flex;
                align-items: baseline;
                margin-top: 12px;

                >div:nth-child(1) {
                    width: 20px;
                    height: 20px;
                    background: #E3E3E3;
                    border-radius: 3px 3px 3px 3px;
                    text-align: center;
                    line-height: 20px;
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: normal;
                    font-size: 15px;
                    color: #333333;
                    flex-shrink: 0;
                }

                >div:nth-child(2) {
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: normal;
                    font-size: 14px;
                    color: #555555;
                    margin-left: 10px;
                }
            }
        }
    }
}

img {
    width: 100%;
}
</style>